import ToolModel from '@/models/Tool'

// initial state
const state = () => ({
  tools: []
})

// getters
const getters = {
  tools: (state) => state.tools
}

// actions
const actions = {
  async find({ commit }, params) {
    console.log('tool/find params >>>>>>', params)
    const { data } = await this.$axios.get('/tools', {
      params
    })

    commit('setTools', data.tools.map((item) => new ToolModel(item)))
  }
}

// mutations
const mutations = {
  hydrate(state) {
    if (state.tools) {
      state.tools = state.tools.map((item) => new ToolModel(item))
    }
  },
  setTools(state, data) {
    state.tools = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
