<template>
  <div class="auth-flow">
    <UserSignUpLandingModal
      v-if="route === STEP_SIGNUP_LANDING"
      :is-authorizing-linkedin="isRequesting"
      :on-authorize-with-linkedin="onAuthorizeWithLinkedin"
      :on-goto-sign-in="onGotoSignIn"
      :on-goto-sign-up-with-email="onGotoSignUpWithEmail"
      @hide="onClose"
    />
    <UserSignUpModal
      v-if="route === STEP_SIGNUP"
      :on-authorize-with-linkedin="onAuthorizeWithLinkedin"
      :on-goto-sign-in="onGotoSignIn"
      :defaults="defaults"
      @hide="onClose"
      @exists="onGotoSignExits"
    />
    <UserSignInModal
      v-if="route === STEP_SIGNIN"
      :hide-sign-up-button="disableSignUp"
      :is-authorizing-linkedin="isRequesting"
      :on-authorize-with-linkedin="onAuthorizeWithLinkedin"
      :on-goto-forgot-password="onGotoForgotPassword"
      :on-goto-sign-up="onGotoSignUpWithEmail"
      :defaults="defaults"
      @hide="onClose"
    />
    <UserForgotPasswordModal
      v-if="route === STEP_FORGOT_PASSWORD"
      :on-goto-sign-in="onGotoSignIn"
      @hide="onClose"
    />

    <UserSignExistsModal
      v-if="route === STEP_SIGN_EXISTS"
      :email="existsEmail"
      :on-goto-forgot-password="onGotoForgotPassword"
      @hide="onClose"
    />
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import UserSignUpModal from '@/modals/user/UserSignUpModal'
import UserSignInModal from '@/modals/user/UserSignInModal'
import UserForgotPasswordModal from '@/modals/user/UserForgotPasswordModal'
import UserSignUpLandingModal from '@/modals/user/UserSignUpLandingModal'
import UserSignExistsModal from '@/modals/user/UserSignExistsModal'

const STEP_SIGNIN = 'signin'
const STEP_SIGN_EXISTS = 'sign-exists'
const STEP_FORGOT_PASSWORD = 'signup-forgot-password'
const STEP_SIGNUP_LANDING = 'signup-landing'
const STEP_SIGNUP = 'signup'

export default {
  name: 'AuthFlow',
  components: {
    UserSignUpLandingModal,
    UserForgotPasswordModal,
    UserSignInModal,
    UserSignUpModal,
    UserSignExistsModal
  },
  mixins: [validationMixin],
  props: {
    onCancel: {
      type: Function,
      required: true
    },
    onAuthSuccess: {
      type: Function,
      required: true
    },
    initialRoute: {
      type: String
    },
    disableSignUp: {
      type: Boolean,
      default: false
    },
    defaults: {
      type: Object
    }
  },
  data() {
    return {
      STEP_SIGNIN,
      STEP_SIGN_EXISTS,
      STEP_FORGOT_PASSWORD,
      STEP_SIGNUP_LANDING,
      STEP_SIGNUP,

      existsEmail: null,

      isRequesting: false,
      route: this.initialRoute || STEP_SIGNUP_LANDING
    }
  },
  computed: {
    userIsLoggedIn() {
      return this.$store.getters['user/isLoggedIn']
    }
  },
  watch: {
    userIsLoggedIn(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.onAuthSuccess()
      }
    }
  },
  mounted() {
    // user is already logged in.
    if (this.userIsLoggedIn) {
      this.onAuthSuccess()
    }
  },
  methods: {
    onClose(evt) {
      evt.preventDefault()

      if (['headerclose', 'hide'].includes(evt.type)) {
        this.onCancel()
      }
    },

    async onAuthorizeWithLinkedin() {
      if (this.isRequesting) {
        return
      }

      this.isRequesting = true

      const { authorized, userExists } = await this.$store.dispatch(
        'user/authorizeWithLinkedin',
        {
          allowRegistration:
            this.route === STEP_SIGNUP || this.route === STEP_SIGNUP_LANDING
        }
      )

      this.isRequesting = false

      if (authorized && !userExists && this.disableSignUp) {
        alert('You do not have an account yet, please signup first.')
      }
    },
    onGotoSignUpWithEmail() {
      this.route = STEP_SIGNUP
    },
    onGotoSignIn() {
      this.route = STEP_SIGNIN
    },
    onGotoForgotPassword() {
      this.route = STEP_FORGOT_PASSWORD
    },
    onGotoSignExits(email) {
      this.route = STEP_SIGN_EXISTS
      this.existsEmail = email
    }
  }
}
</script>
