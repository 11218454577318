export function buildMetaHead(inputs) {
  const head = {};
  const meta = [];
  const link = [];
  const script = [];

  if (inputs.title || inputs.mandatoryTitle) {
    let formattedTitle = inputs.mandatoryTitle
      ? inputs.mandatoryTitle
      : inputs.title && inputs.title !== 'The Hub'
      ? 'The Hub | ' + inputs.title
      : 'The Hub';

    head.title = formattedTitle;
    meta.push({
      hid: 'og:title',
      property: 'og:title',
      content: formattedTitle
    });
    meta.push({
      hid: 'twitter:title',
      name: 'twitter:title',
      content: formattedTitle
    });
  }

  if (inputs.path) {
    inputs.url = inputs.isPreview ? `https://${inputs.path}` : process.env.BASE_URL + inputs.path;
  }

  if (inputs.url) {
    meta.push({ hid: 'twitter:url', name: 'twitter:url', content: inputs.url });
    meta.push({ hid: 'og:url', property: 'og:url', content: inputs.url });
    link.push({ hid: 'link:canonical', rel: 'canonical', href: inputs.url });
  }

  if (inputs.description) {
    inputs.description =
      inputs.description.length > 200
        ? inputs.description.substr(0, 200) + ' ...'
        : inputs.description;

    meta.push({
      hid: 'description',
      name: 'description',
      content: inputs.description
    });
    meta.push({
      hid: 'og:description',
      property: 'og:description',
      content: inputs.description
    });

    meta.push({
      hid: 'twitter:description',
      name: 'twitter:description',
      content: inputs.description
    });
  }

  if (inputs.imageUrl) {
    meta.push({
      hid: 'og:image',
      property: 'og:image',
      content: inputs.imageUrl
    });
    meta.push({
      hid: 'twitter:image',
      property: 'twitter:image',
      content: inputs.imageUrl
    });
  }

  if (inputs.googleJob) {
    script.push({
      hid: 'ldjson-schema',
      type: 'application/ld+json',
      innerHTML: inputs.googleJob
    });
  }

  head.meta = meta;
  head.link = link;
  head.script = script;

  return head;
}
