import CompanyModel from '@/models/Company'
// import { transform as transformJob } from '@/transformers/job';

// initial state
const state = () => ({
  startups: null,
  startup: null
})

// getters
const getters = {
  startups: (state) => state.startups
}

// actions
const actions = {
  async find({ commit }, params) {
    const { data } = await this.$axios.get('/companies', {
      params
    })

    commit('setStartups', {
      ...data,
      docs: data.docs.map((item) => new CompanyModel(item))
    })
  },

  async get({ commit }, id) {
    const { data } = await this.$axios.get('/companies/' + id)

    commit('setStartup', new CompanyModel(data))
  },

  async getByDomain({ commit }, domain) {
    const { data } = await this.$axios.get('/companies/domain/' + domain)

    commit('setStartup', new CompanyModel(data))
  },

  async follow({ commit }, { startup, value }) {
    await this.$axios.post(
      '/companies/' + startup.key + '/' + (value ? 'follow' : 'unfollow')
    )

    commit('setStartupFollowed', {
      startup,
      value
    })
  }
}

// mutations
const mutations = {
  hydrate(state) {
    if (state.startups) {
      state.startups.docs = state.startups.docs.map(
        (item) => new CompanyModel(item)
      )
    }

    if (state.startup) {
      state.startup = new CompanyModel(state.startup)
    }
  },
  setStartups(state, data) {
    state.startups = data
  },
  setStartup(state, data) {
    state.startup = data
  },
  setStartupFollowed(state, { startup, value }) {
    startup.followed = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
