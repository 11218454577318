import find from 'lodash/find';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { companyPerkIcons, jobRoleVisuals, priceOptions } from '@/constants';

// initial state
const state = () => ({
  preferredCountryCode: undefined,
  site: undefined,
  industries: [],
  sdgs: [],
  interested: undefined,
  job: undefined,
  company: undefined,
  investor: undefined,
  toolCategories: [],
  partnerCategories: [],
  activeJob: undefined
});
// getters
const getters = {
  site(state) {
    return state.site;
  },
  siteCountries(state) {
    return get(state, 'site.countries');
  },
  toolCategories(state) {
    return state.toolCategories;
  },
  partnerCategories(state) {
    return state.partnerCategories;
  },
  siteCountriesExNI(state) {
    const siteCountries = get(state, 'site.countries');
    const siteCountriesExNI = siteCountries.filter((item) => item.value !== 'NI');
    return siteCountriesExNI;
  },
  siteCountryByCode(state, getters) {
    return (code) => {
      return find(getters.siteCountries, { value: code });
    };
  },
  siteCountriesInclRemoteExNI(state) {
    let siteCountries = get(state, 'site.countries');
    const siteCountriesExNI = siteCountries.filter((item) => item.value !== 'NI');
    siteCountriesExNI.push({ text: 'Remote', value: 'REMOTE' });
    return siteCountriesExNI;
  },
  sitePreferredCountryCode(state, getters) {
    return state.preferredCountryCode || getters.siteIpDetectedCountryCode;
  },
  sitePreferredPriceByCountryCode() {
    return (countryCode) => {
      return find(priceOptions, { countryCode });
    };
  },
  siteIpDetectedCountryCode(state) {
    return get(state, 'site.ipDetectedCountryCode');
  },
  siteCountryCode() {
    return 'DK';
  },
  job(state) {
    return state.job;
  },
  jobSorting(state) {
    return state.job.sorting;
  },
  jobPositionTypes(state) {
    return get(state, 'job.positionTypes');
  },
  jobPositionTypeById(state, getters) {
    return (id) => {
      return find(getters.jobPositionTypes, { id });
    };
  },
  jobReceiveApplicants(state) {
    return get(state, 'job.receiveApplicants');
  },
  jobStatus(state) {
    return get(state, 'job.status');
  },
  jobStatusByValue(state, getters) {
    return (value) => {
      return find(getters.jobStatus, { value });
    };
  },
  jobRoles(state) {
    return get(state, 'job.roles', [])
      .map((role) => ({
        ...role,
        ...(jobRoleVisuals[role.value] || {})
      }))
      .sort((a, b) => {
        if (a.value === 'other') {
          return 1;
        } else if (b.value === 'other') {
          return -1;
        } else {
          return a.label > b.label ? 1 : -1;
        }
      });
  },
  jobRoleByValue(state, getters) {
    return (value) => {
      return find(getters.jobRoles, { value });
    };
  },
  jobRoleLabelByValue(state, getters) {
    return (value) => {
      const role = getters.jobRoleByValue(value);
      return role && role.label;
    };
  },
  interested(state) {
    return state.interested;
  },
  interestedStatus(state) {
    return get(state, 'interested.status');
  },
  interestedStatusesByBoard(state) {
    return (board) => {
      let columns = board.columns || [];
      let statuses = get(state, 'interested.status').map((el) => el);

      const index = statuses.findIndex((s) => s.value === 'custom');

      Array.prototype.splice.apply(statuses, [index, 1, ...columns]);

      return statuses.map((item) => ({
        ...(item.value
          ? pick(item, ['label', 'value'])
          : {
              value: item.id,
              label: item.title,
              custom: true
            })
      }));
    };
  },
  interstedStatusByValue(state, getters) {
    return (value) => {
      return find(getters.interestedStatus, { value });
    };
  },
  interstedStatusLabelByValue(state, getters) {
    return (value) => {
      const status = getters.interstedStatusByValue(value);
      return status && status.label;
    };
  },
  industries(state) {
    return get(state, 'industries');
  },
  industryByValue(state, getters) {
    return (value) => {
      return find(getters.industries, { value });
    };
  },
  sdgs(state) {
    return get(state, 'sdgs');
  },
  company(state) {
    return state.company;
  },
  companyFunding(state) {
    return get(state, 'company.funding');
  },
  companyFundingStages(state) {
    return get(state, 'company.fundingStages');
  },
  companyPerks(state) {
    return get(state, 'company.perks').map((perk) => ({
      ...perk,
      icon: companyPerkIcons[perk.key]
    }));
  },
  companyPerkById(state, getters) {
    return (id) => {
      return find(getters.companyPerks, { id });
    };
  },
  companyStages(state) {
    return get(state, 'company.stages');
  },
  companyBusinessModels(state) {
    return get(state, 'company.businessModels');
  },
  companySlackNotifications(state) {
    return get(state, 'company.slackNotifications');
  },
  companyStageByValue(state, getters) {
    return (value) => {
      return find(getters.companyStages, { value });
    };
  },
  companyBusinessModelByValue(state, getters) {
    return (value) => {
      return find(getters.companyBusinessModels, { value });
    };
  },
  companyFundingStagesByValue(state, getters) {
    return (value) => {
      return find(getters.companyFundingStages, { value });
    };
  },
  companyNumberOfEmployees(state) {
    return get(state, 'company.numberOfEmployees');
  },
  companyKeyFigureNames(state) {
    return get(state, 'company.keyFigureNames');
  },
  investor(state) {
    return state.investor;
  },
  investorCategories(state) {
    return get(state, 'investor.categories');
  },
  investorInvestmentRanges(state) {
    return get(state, 'investor.investmentRanges');
  },
  investorMarkets(state) {
    return get(state, 'investor.markets');
  },
  investorContactPreferences(state) {
    return get(state, 'investor.contactPreferences');
  },
  investorCategoryLabelByValue(state, getters) {
    return (value) => {
      const category = find(getters.investorCategories, { value });
      return category.label;
    };
  },
  jobCategories(state, getters) {
    const jobRoles = getters.jobRoles;

    function itemByRole(key) {
      const item = find(jobRoles, { value: key });
      return {
        name: item.label,
        color: item.color,
        icon: item.icon,
        roles: [key]
      };
    }

    function itemByGroupOfRoles(name, icon, color, roleKeys) {
      const roles = jobRoles.filter((role) => roleKeys.includes(role.value));
      return {
        name,
        color,
        icon,
        roles: roles.map((role) => role.value)
      };
    }

    return [
      itemByRole('businessdevelopment'),
      itemByGroupOfRoles('Customer Success', 'customerservice', '#65EFE2', [
        'customerservice',
        'customersuccess'
      ]),
      itemByRole('design'),
      itemByGroupOfRoles('Development', 'development', '#f71f3e', [
        'fullstackdeveloper',
        'frontenddeveloper',
        'backenddeveloper'
      ]),
      itemByGroupOfRoles('Mobile Development', 'mobiledevelopment', '#d93adf', [
        'mobiledevelopment'
      ]),
      itemByRole('operations'),
      itemByRole('marketing'),
      itemByRole('projectmanagement'),
      itemByRole('sales')
    ];
  },
  emailPlaceholders(state) {
    return state.emailPlaceholders;
  },
  uiFeatures(state) {
    return state.uiFeatures || {};
  },
  kanbanColumnColors(state) {
    return state.kanban.columnColors;
  },
  activeJob(state) {
    return state.activeJob;
  }
};

// actions
const actions = {
  async load({ commit, dispatch }) {
    const { data } = await this.$axios.get('/site/configuration');
    commit('SET_CONFIGURATION', data);

    const preferredCountryCode = this.$cookies.get('preferred-country-code');
    if (preferredCountryCode) {
      dispatch('setPreferredCountryCode', preferredCountryCode);
    }
  },
  setPreferredCountryCode({ commit }, countryCode) {
    if (countryCode === 'REMOTE') {
      return;
    }

    this.$cookies.set('preferred-country-code', countryCode);
    commit('SET_PREFERRED_COUNTRY_CODE', countryCode);
    // console.log('UPDATED PREFERRED COUNTRY CODE', countryCode)
  },
  setActiveJob({ commit }, payload) {
    // console.log('SET_ACTIVE_JOB !!!!!!!!!!!!!!@@@!!!!!!!!!', payload)
    commit('SET_ACTIVE_JOB', payload);
  }
};

// mutations
const mutations = {
  SET_CONFIGURATION(state, data) {
    Object.assign(state, data);
  },
  SET_PREFERRED_COUNTRY_CODE(state, countryCode) {
    state.preferredCountryCode = countryCode;
  },
  SET_ACTIVE_JOB(state, payload) {
    state.activeJobJob = payload.job;
    payload.cb();
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
