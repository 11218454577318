<template>
  <DefaultLayout v-if="userIsLoggedIn">
    <template slot="header">
      <Headerbar />
    </template>
  </DefaultLayout>
</template>

<script>
import Headerbar from '@/components/Headerbar'
import DefaultLayout from '@/layouts/default'
import ManagerGuardMixin from '@/mixins/managerGuard'
import { buildMetaHead } from '@/helpers/meta'

export default {
  name: 'StartupLayout',
  components: {
    DefaultLayout,
    Headerbar
  },
  mixins: [ManagerGuardMixin],
  middleware: ['isClientManager', 'redirectCompanyDraftToForm'],
  head() {
    return buildMetaHead({
      title: 'Client Portal',
      path: this.$route.path
    })
  },
  computed: {
    userIsLoggedIn() {
      return this.$store.getters['user/isLoggedIn']
    }
  }
}
</script>
